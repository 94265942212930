// var for box shadow of gradient buttons
// Buttons
.btn {
  box-shadow: none;
  font-weight: 500;

  // gradient button on hover transition
  &[class*='bg-gradient-'] {
    transition: all 0.2s ease;
    &:hover {
      transform: translateY(-2px);
    }
  }

  // Icon button padding
  &.btn-icon {
    padding: 0.715rem 0.736rem;
  }
  &.btn-sm {
    &.btn-icon {
      padding: 0.5rem;
      line-height: 0.5;
    }
  }
  &.btn-lg {
    &.btn-icon {
      padding: 1rem;
      line-height: 0.75;
    }
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    outline: none;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: none !important;
  }

  // feather icons inside btn
  .feather {
    vertical-align: bottom;
  }
}

// For Waves Input Padding
.btn.waves-input-wrapper {
  padding: 0;
}

@include media-breakpoint-down(sm) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
}

// Remove cursor-pointer from button if button is disabled
// * setting it to inherit will auto adept cursor
.waves-effect {
  cursor: inherit;
}

.css-rq1m3u {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  opacity: 0.9;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  letter-spacing: 0.43px;
  font: {
      weight: 500;
      size: 0.8125rem;
  }
  border: 1px solid rgb(168, 170, 174);
  color: rgb(168, 170, 174) !important;
  min-width: 50px;
  text-transform: none;
  transition: {
      property: background-color, box-shadow, border-color, color, transform;
      duration: 150ms;
      timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      delay: 0ms;
  }
  line-height: 1.231;
  border-radius: 4px;
  padding: 0.3125rem 0.8125rem;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  &.btn-secondary {
    border-color: rgb(168, 170, 174) !important;
    background-color: transparent !important// your desired styles;
    // ...
}
}

